import restaurant from "./images/prestij.png";
import img_card from "./images/fg5.png";
import imgheaderrestaurant from "./images/headerrestaurant.jpg";
import imgheader from "./images/header.webp";
import imgheaderm from "./images/headermb.webp";
import img2 from "./images/img2.jpg";
import imgheadermiini from "./images/header.jpg";
import imgheadermiinimb from "./images/header.jpg";

{/*valeriacosarca@gmail.com*/}

const data = {
    introData: [{
        title: "Invitatie Online",
        mire: "Vasile",
        mireasa: "Tatiana",
        data: "30 Aprilie 2023",
        data_confirmare: "10 aprilie 2023",
        savedata: "~ Salvează Data ~",
        imgdesktop: imgheader,
        imgmobil: imgheaderm,
        email: "vaseaaturcan@gmail.com", 
       tel: "+373 603 13 023",
       phone: "tel:+37360313023",
       viber: "viber://chat?number=%2B33666780536",
       whatsapp: "https://wa.me/+33666780536",
       messenger: "https://www.messenger.com/t/tatiana.caracas.37",
       tel1: "+373xxx",
       phone1: "tel:+373xxx",
       viber1: "viber://chat?number=%2B373xxx",
       whatsapp1: "https://wa.me/+373xxx",
       messenger1: "https://www.messenger.com/t/iurii.denis",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Locația Evenimentului",
            name: "Prestij",
            data: "30 aprilie 2023, duminică, ora 15:00",
            adress: "or. Căușeni, R. Moldova",
            harta: "https://goo.gl/maps/TercVuHvwYbAF1cj7",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10952.510026321732!2d29.3794944!3d46.6637437!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c9ac44ab2173a1%3A0x9732d0cea15ad8f!2sRestaurantul%20%22Prestij%22!5e0!3m2!1sro!2s!4v1679383362394!5m2!1sro!2s"
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Ne căsătorim!",
            title2: "VĂ INVITĂM LA NUNTA NOASTRĂ!",
            message: "În ziua în care ne vom uni destinele, vrem să fim inconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
            ps: "",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
             
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgheaderrestaurant,
            title: "Nu uitați să luați cu voi un car de voie bună și opinci de dansat!!!",
            message: "P.S: Vom fi recunoscători pentru confirmarea prezenței DVS!",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "De aici începe totul.",
            message: "\"Dragostea constă în dorința de a da ceea ce este al tău altuia și de a simți fericirea acestuia ca și cum ar fi a ta. \" – Emanuel Swdenborg",
        }
    ],

}

export default data;